import React from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import { SeoQuery } from "../../graphql-types"

type Image = {
  src: string
  height: number
  width: number
}

type Meta = {
  name?: string
  content?: any
  property?: string
}

type Props = {
  description?: string
  lang?: string
  meta?: Meta[]
  title: string
  image?: Image
  pathname?: string
}

export default class SEO extends React.Component<Props> {
  static defaultProps = {
    lang: `ru`,
    meta: [],
    description: ``,
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query Seo {
            site {
              siteMetadata {
                siteUrl
                title
                description
              }
            }
          }
        `}
        render={data => this.renderWithData(data)}
      />
    )
  }

  renderWithData({ site }: SeoQuery) {
    const { description, lang, meta, image: metaImage, title, pathname } = this.props

    const metaDescription = description || site.siteMetadata.description
    const image = metaImage && metaImage.src ? `${site.siteMetadata.siteUrl}${metaImage.src}` : null
    const canonical = pathname ? `${site.siteMetadata.siteUrl}${pathname}` : null

    let resMeta: Meta[] = [
      {
        name: `description`,
        content: metaDescription,
      },
      {
        property: `og:title`,
        content: title,
      },
      {
        property: `og:description`,
        content: metaDescription,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        name: `twitter:card`,
        content: `summary`,
      },
      {
        name: `twitter:title`,
        content: title,
      },
      {
        name: `twitter:description`,
        content: metaDescription,
      },
    ]
    resMeta = resMeta.concat(
      metaImage
        ? [
            {
              property: "og:image",
              content: image,
            },
            {
              property: "og:image:width",
              content: metaImage.width,
            },
            {
              property: "og:image:height",
              content: metaImage.height,
            },
            {
              name: "twitter:card",
              content: "summary_large_image",
            },
          ]
        : [
            {
              name: "twitter:card",
              content: "summary",
            },
          ]
    )
    resMeta = resMeta.concat(meta)

    return (
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        link={
          canonical
            ? [
                {
                  rel: "canonical",
                  href: canonical,
                },
              ]
            : []
        }
        meta={resMeta}
      />
    )
  }
}
