import React from "react"
import { Location as HLocation } from "history"
import { Link } from "gatsby"

import { isSSR } from "utils/ssr"

export type LocationState = {
  pathname: string // url without query params like window.location.pathname
  query?: string
}

export const makeLocationState = (pathname: string, query: string): LocationState => ({ pathname, query })

export const getLocationState = (location: HLocation<LocationState>): LocationState => {
  if (isSSR()) {
    return null
  }

  if (location.state && location.state.query) {
    return location.state
  }

  return { pathname: location.pathname, query: location.search }
}

type Props = {
  state: LocationState
  to: string
}

export default class StateLink extends React.PureComponent<Props> {
  render() {
    const { state, to, ...restProps } = this.props as any
    const toParts = to.split(`#`)
    const primaryPart = toParts[0]
    const hashPart = toParts.length === 2 ? `#${toParts[1]}` : ``
    return (
      <Link to={`${primaryPart}${state && state.query ? state.query : ""}${hashPart}`} {...restProps}>
        {this.props.children}
      </Link>
    )
  }
}
