/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { memo } from "react"
import { FaCalculator, FaClock, FaMoneyBillWave, FaBuilding } from "react-icons/fa"

import styled from "utils/styled"
import mediaQuery from "utils/mediaQuery"
import StateLink, { LocationState } from "components/state-link"

const Nav = styled.nav`
  margin: 0 auto;
  max-width: ${mediaQuery.screens.large};
  padding-left: ${p => p.theme.space[3]};
  padding-top: ${p => p.theme.space[3]};
  padding-bottom: ${p => p.theme.space[3]};
  font-size: ${p => p.theme.fontSizes[1]};
`

const NavText = memo(styled.span`
  color: white;
`)

const NavLink = styled(StateLink)`
  margin-right: ${p => p.theme.space[2]};
  display: inline;
  margin-bottom: 0;
  margin-left: ${p => p.theme.space[3]};
  color: white;
  &:hover {
    color: ${p => p.theme.colors.primaryLightAnalogous};
  }

  svg {
    display: inline-block;
    width: 1em;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
  }
`

const NavItem = styled.div`
  display: inline;
`

const Container = styled.header`
  background: ${p => p.theme.colors.primaryLight};
  margin-bottom: ${p => p.theme.space[3]};
  ${mediaQuery.maxLarge} {
    display: none;
  }
`

type Props = {
  locationState: LocationState
}

const Header = React.memo(({ locationState }: Props) => (
  <Container>
    <Nav>
      <NavItem>
        <NavText>Ипотечные калькуляторы:</NavText>
        <NavLink state={locationState} to="/">
          <FaCalculator /> Основной
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink state={locationState} to="/ипотечный-калькулятор-с-досрочным-погашением/">
          <FaClock /> С досрочным погашением
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink state={locationState} to="/калькулятор-ипотеки-с-учетом-инфляции/">
          <FaMoneyBillWave /> С инфляцией
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink state={locationState} to="/ипотека-или-накопление-и-аренда-что-выгоднее/">
          <FaBuilding /> Ипотека или аренда
        </NavLink>
      </NavItem>
    </Nav>
  </Container>
))

export default Header
