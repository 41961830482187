/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "theme-ui"
import { Grid } from "react-flexbox-grid"

import Header from "./header"
import PageComments from "./comments"
import theme from "utils/theme"
import { LocationState } from "./state-link"

const hrSx = { mt: 2 }

type Props = {
  children: any
  locationState: LocationState
}

const Layout = ({ children, locationState }: Props) => (
  <div>
    <ThemeProvider theme={theme}>
      <Header locationState={locationState} />
      <Grid>
        <main>{children}</main>
        <div sx={hrSx}>
          <hr />
        </div>
        <PageComments locationState={locationState} />
        <div sx={hrSx}>
          <hr />
        </div>
        <footer>© {new Date().getFullYear()}</footer>
      </Grid>
    </ThemeProvider>
    <script async src="https://w.appzi.io/w.js?token=4FBnU"></script>
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
