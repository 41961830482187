import Typography from "typography"
import Theme from "typography-theme-alton"

// We fetch them with font-display: swap by web-font-loader,
// see https://github.com/KyleAMathews/typography.js/issues/211.
delete Theme.googleFonts

const typography = new Typography(Theme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
export const theme = Theme
