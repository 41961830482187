const min = (width: number) => `only screen and (min-width: ${width}px)`
const max = (width: number) => `only screen and (max-width: ${width - 1}px)`

type Screens = {
  small: number
  medium: number
  large: number
}

type MediaQuery = {
  screens: Screens
  minSmall?: string
  maxSmall?: string
  minMedium?: string
  maxMedium?: string
  minLarge?: string
  maxLarge?: string
}

const mediaQuery: MediaQuery = {
  screens: {
    // values are in pixels
    small: 576,
    medium: 768,
    large: 992,
  },
}

const funcs = [min, max]
const funcNames = [`min`, `max`]

for (const key of Object.keys(mediaQuery.screens)) {
  const upperKey = key.charAt(0).toUpperCase() + key.slice(1)
  for (let i = 0; i < funcs.length; i++) {
    const func = funcs[i]
    const name = funcNames[i]
    // css query
    const query = func(mediaQuery.screens[key])
    mediaQuery[name + upperKey] = `@media ` + query
    // js query (see window.matchMedia)
    mediaQuery[name + upperKey + `Js`] = query
  }
}

export default mediaQuery
