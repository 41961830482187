import { LocationState } from "components/state-link"
import React from "react"
import LazyLoad from "react-lazyload"
import { isSSR } from "utils/ssr"
import styled from "utils/styled"

interface IPageCommentsProps {
  locationState: LocationState
}

const CommentsHeader = styled.div`
  font-size: ${p => p.theme.fontSizes[3]};
`

class PageComments extends React.PureComponent<IPageCommentsProps> {
  private readonly divId = "comments"

  constructor(props: IPageCommentsProps) {
    super(props)

    if (!isSSR()) {
      const w = window as any
      if (!w.AnyComment) {
        w.AnyComment = []
        w.AnyComment.Comments = []
        w.AnyComment.Comments.push({
          root: this.divId,
          app_id: 4097,
          language: "ru",
        })
      }
    }
  }

  public componentDidMount() {
    // Loading script once doesn't work :(
    var s = document.createElement("script")
    s.type = "text/javascript"
    s.async = true
    s.src = "https://widget.anycomment.io/comment/embed.js"
    var sa = document.getElementsByTagName("script")[0]
    sa.parentNode.insertBefore(s, s.nextSibling)
  }

  public render() {
    return (
      <>
        <CommentsHeader>Комментарии</CommentsHeader>
        <div id={this.divId} />
      </>
    )
  }
}

// Use lazyload not only because it's lazy but also because it
// doesn't render elements with hidden (display: none) parent.
// It saves us from unneeded requests on mobile devices
// when vk isn't visible.
export default (props: IPageCommentsProps) => (
  <LazyLoad>
    <PageComments {...props} />
  </LazyLoad>
)
